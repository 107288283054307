import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Typography,
    Button,
    Grid,
    FormControl,
    TextField,
    InputLabel,
    TextareaAutosize 
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { NotificationApi } from "../../../apis";



const useStyles = makeStyles({
    inputLabels: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#31394D",
        textAlign: "left",
        marginBottom: "10px",
    },
});
const NotificationList = () => {

    const navigate = useNavigate();
    const [service, setService] = useState(null); // State to store service data
    const [editorState, setEditorState] = useState('');

    const validationSchema = yup.object({
        title: yup.string().required("Title is required"),
        description: yup.string().required("Description is required"),
    });

    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            NotificationApi.SendNotification(values).then((res) => {
                
                navigate("/notification");
                // clear the form
                formik.resetForm();
            });
        },
    });

  

    return (
        <Box
            sx={{
                background: "#FFFFFF",
                borderRadius: "14px",
                padding: "30px",
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box display={"flex"}>
                        <Typography ml={1} sx={{ fontWeight: "600", fontSize: "25px" }}>
                            Send Notification
                        </Typography>
                    </Box>
                    <Box pt={0}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                background: "secondary.main",
                                borderRadius: "5px",
                                border: "none",
                                boxShadow: "none",
                                color: "#FFFFFF",
                                outline: "none",
                                "&:hover": {
                                    background: "secondary.main",
                                    outline: "none",
                                },
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
                <Box
                    mt={2}
                    sx={{

                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} className={classes.inputLabels}>
                                Title:
                            </InputLabel>
                            <FormControl
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    id="title"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel mt={2} className={classes.inputLabels}>
                                Description:
                            </InputLabel>
                                
                            <FormControl

                                sx={{   
                                    width: "100%",
                                }}
                            >
                                <TextareaAutosize 
                                    fullWidth
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                    variant="outlined"
                                    minRows={5}
                                    style={{width:"100%",
                                    padding:"10px",
                                    fontSize:"16px",

                                    }}
                                    
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    );
}

export default NotificationList;