import React, { useEffect } from "react";

import { Box, Typography, Button, Grid, FormControl, TextField, InputLabel, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { RewardApi } from "../../../apis";

const useStyles = makeStyles({
    inputLabels: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#31394D",
        textAlign: "left",
        marginBottom: "10px",
    },
});

const RewardForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const validationSchema = yup.object({
        description: yup.string().required("Description is required"),
        points: yup.number().required("Points is required"),
    });

    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            description: "",
            points: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (id) {
                RewardApi.updateReward(id, values).then((res) => {
                    navigate("/reward");
                    formik.resetForm();
                });
            } else {
                RewardApi.createReward(values).then((res) => {
                    navigate("/reward");
                    formik.resetForm();
                });
            }
        },
    });

    useEffect(() => {
        if (id) {
            RewardApi.getRewardById(id).then((res) => {
                // description and points are the fields in the database
                formik.setFieldValue("description", res.data.data.description);
                formik.setFieldValue("points", res.data.data.coins);
            });
        }
    }
        , [id]);

    return (
        <Box
            sx={{
                background: "#FFFFFF",
                borderRadius: "14px",
                padding: "30px",
            }}
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                }}
            >
                {
                    id ? "Edit Reward" : "Add Reward"
                }
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <InputLabel sx={{
                            mt: 2,
                        }} className={classes.inputLabels}>Description</InputLabel>

                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel className={classes.inputLabels}>Points</InputLabel>
                        <FormControl fullWidth>

                            <TextField
                                fullWidth
                                id="points"
                                name="points"
                                value={formik.values.points}
                                onChange={formik.handleChange}
                                error={formik.touched.points && Boolean(formik.errors.points)}
                                helperText={formik.touched.points && formik.errors.points}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{

                                fontWeight: 500,
                                fontSize: "14px",
                                background: "secondary.main",
                                borderRadius: "5px",
                                border: "none",
                                boxShadow: "none",
                                color: "#FFFFFF",
                                textAlign: "right",
                                outline: "none",
                                "&:hover": {
                                    background: "secondary.main",
                                    outline: "none",
                                },
                            }}

                        >
                            {id ? "Update Reward" : "Add Reward"}
                        </Button>
                    </Grid>

                </Grid>
            </form>
        </Box>
    );
}

export default RewardForm;
