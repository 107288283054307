import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { ServicesApi } from "../../../apis";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
    marginBottom: "10px",
  },
});

const AddService = () => {
  const { id } = useParams();
  const isEditMode = !!id;
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [service, setService] = useState(null); // State to store service data

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    image: yup.mixed().required("Image is required"),
  });

  const classes = useStyles();

  useEffect(() => {

    if (isEditMode) {
      ServicesApi.getServiceById(id)
        .then((res) => {
          const serviceData = res.data;
          console.log(res.data);
          setService(serviceData);
          formik.setFieldValue("name", res.data.data.name);
          console.log("asdfghjkix", res.data.data.image);
          formik.setFieldValue("image", res.data.data.image);

        })
        .catch((error) => {
          console.error("Error fetching service:", error);
        });
    }
  }, [id, isEditMode]);

  console.log("service", service);
  const formik = useFormik({
    initialValues: {
      name: isEditMode ? (service ? service.name : "") : "",
      image: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form values:", values);

      const formData = new FormData();
      formData.append("name", values.name);
      if(
        // check the image is binary or not
        values.image instanceof File
      ){
      formData.append("image", values.image);
      }
      if (isEditMode) {
        ServicesApi.updateService(id, formData).then((res) => {
          window.location = "/services";
          
        });
      } else {
        ServicesApi.addServices(formData).then((res) => {
          window.location = "/services";
        });
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
    formik.setFieldValue("image", file);
  };

  const handleImageDelete = () => {
    setImage(null);
    setImagePreview(null);
    formik.setFieldValue("image", "");
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => navigate("/services")} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              {isEditMode ? "Edit Service" : "Add Service"}
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                "&:hover": {
                  background: "secondary.main",
                  outline: "none",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Box
          mt={2}
          sx={{

          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Name:
              </InputLabel>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            {/* Image upload section */}
            <Grid item xs={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Image:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="image-upload"
                />
                <label htmlFor="image-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      height: "55px",
                      textTransform: "none", color: "black"
                    }}
                  >
                    Upload Image
                  </Button>
                </label>
                {/* Display image preview */}
                {imagePreview && (
                  <Box
                    mt={2}
                    position="relative"
                    sx={{ display: "inline-block", position: "relative" }}
                  >
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img
                        src={imagePreview}
                        alt="Uploaded preview"
                        width={100}
                        height={100}
                        style={{ display: "block" }}
                      />
                      <IconButton
                        onClick={handleImageDelete}
                        sx={{
                          position: "absolute",
                          top: "-8px",
                          right: "-10px",
                          color: "grey",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Box>
                )}
                {
                  !imagePreview && isEditMode && service && service.data.image && (
                    <Box mt={2} position="relative" sx={{ display: "inline-block", position: "relative" }}>
                      <div style={{ position: "relative", display: "inline-block" }}>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/images/${service.data.image}`}
                          alt="Uploaded preview"
                          width={100}
                          height={100}
                          style={{ display: "block" }}
                        />
                        {/* <IconButton
                          onClick={handleImageDelete}
                          sx={{
                            position: "absolute",
                            top: "-8px",
                            right: "-10px",
                            color: "grey",
                          }}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                      </div>
                    </Box>
                  )
                }
                {/* Display caption */}
                <Typography variant="caption" color="black" ml={1}>
                  {imagePreview && "Image uploaded successfully"}
                </Typography>
              </FormControl>
            </Grid>

          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddService;
