import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button, Modal, Backdrop, Fade, IconButton } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom'
import "./styles.css";
import { ServicesApi } from "../../../apis";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
// 
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
function ServiceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [descriptionPreview, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const getServicesDetail = async () => {
    try {
      const response = await ServicesApi.getServicesDetail(id);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching service details:", error);
    }
  };
  useEffect(() => {
    getServicesDetail();
  }, []);

  const handlePreview = (description) => {
    console.log(description);
    setDescription(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleDelete = (id) => {
    ServicesApi.deleteServiceDetails(id)
      .then(() => {
        getServicesDetail();
        // console.log(`Service with ID ${id} deleted successfully.`);
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => navigate("/services")} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              View Service Details
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate(`/add-service-detail/${id}`)}
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              background: "secondary.main",
              borderRadius: "5px",
              border: "none",
              boxShadow: "none",
              color: "#FFFFFF",
              outline: "none",
              "&:hover": {
                background: "secondary.main",
                outline: "none",
              },
            }}
          >
            Add Service Details
          </Button>
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ paddingRight: "10px" }}>Service ID</th>
                  <th style={{ paddingLeft: "40px" }}>Title</th>
                  <th style={{ paddingLeft: "40px" }}>Price</th>

                  <th style={{ paddingLeft: "40px" }}>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                 {

                  data.length === 0 && <tr><td colSpan="5" style={{ textAlign: "center" }}>No data found</td></tr>
                }
                {
                data && data.map((service, index) => (
                  <tr key={index}>
                    <td style={{ paddingLeft: "40px" }}>{service.id}</td>

                    <td style={{ paddingLeft: "40px" }}>{service.title}</td>

                    <td style={{ paddingLeft: "40px" }}>{service.price}</td>
                    <td style={{
                      paddingLeft: "40px",
                      //max character length for description


                    }}>{

                        // view the description of the service as html
                        <div
                          style={{
                            maxWidth: "200px", overflowX: "hidden ", overflowY: "hidden", textOverflow: "scroll",
                            height: "200px", hyphens: "auto"

                          }}
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                        ></div>

                      }</td>

                    <td>
                      <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: "auto" }}>
                        <Button
                          style={{ color: 'grey' }}
                          onClick={() => navigate(`/add-service-detail/${service.id}?serviceId=${service.serviceId}`)}
                        >
                          <BorderColorIcon />
                        </Button>
                        <Button onClick={() => handleDelete(service.id)}>
                          <DeleteIcon color="error" />
                        </Button>
                        {/* preview description*/}
                        <Button
                          onClick={() => handlePreview(service.description)}
                          style={{ color: 'green' }}>
                          <VisibilityIcon />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Preview
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div >
            {/* html */}
            <div dangerouslySetInnerHTML={{ __html: descriptionPreview }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

export default ServiceDetails;
