// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#010001',
    },
    secondary: {
      main: '#e2d4cd',
    },
    background: {
      default: '#e2d4cd',
      paper: '#ffffff',
    },
    text: {
      primary: '#010001',
      secondary: '#e2d4cd',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white', // Ensures text in buttons is white by default
        },
      },
    },
  },
});

export default theme;
