import axios from "../axiosConfig";

class Routes {

 
    SendNotification= (val) => {
        let data = axios.post("/api/notification/send-notification", val);
        return data;
    };
 
}



export default new Routes();