import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, AppBar, ListItemButton ,Button } from '@mui/material';
import { Inbox as InboxIcon } from '@mui/icons-material';
import AppRoutes from '../../router/AppRoutes'; // Import the AppRoutes component
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from '../../images/logo.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

const drawerWidth = 240;

const AdminDashboard = () => {
  const navigate = useNavigate(); 

  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
  };
  useEffect(() => {
    if (window.location.pathname.includes('services')) {
      setSelectedMenuItem('services');
    } else if (window.location.pathname.includes('notification')) {
      setSelectedMenuItem('notification');
    } else if (window.location.pathname.includes('reward')) {
      setSelectedMenuItem('reward');
    }
  }, []);
  const renderComponent = () => {
    if (isAuthenticated()) {
      return (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, bgcolor: 'primary.main' }}
          >
            <Toolbar>
              <Typography variant="h6" noWrap component="div" color="secondary.main">
                {/* Add app title here if needed */}
              </Typography>
              <Box sx={{ flexGrow: 1 }} /> {/* Add a flexible space to push the logout button to the right */}
              <Button color="inherit" onClick={handleLogout}>Logout</Button> {/* Add the logout button */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'secondary.main' },
            }}
          >
            <Box  sx={{ backgroundColor: 'secondary.main',}}>
              <img src={logo} alt="Logo" style={{ width: '200px',height:"",padding: '10px' ,marginLeft:"15px"}} />
            </Box> 
            <Box sx={{ overflow: 'auto' }}>
              <List>
                <ListItemButton
                  selected={selectedMenuItem === 'services'}
                  onClick={() => {
                    setSelectedMenuItem('services');
                    navigate('/services')
                  }} //
                  // Use navigate function to handle click event
                >
                  <ListItemIcon>
                    <InboxIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Services" />
                </ListItemButton>
                {/* Add other menu items */}

                <ListItemButton
                  selected={selectedMenuItem === 'notification'}
                  onClick={() => {

                    setSelectedMenuItem('notification');
                    navigate('/notification')

                  }} // Use navigate function to handle click event
                >
                  <ListItemIcon>
                    <NotificationsIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Notification" />
                </ListItemButton>

                <ListItemButton
                  selected={selectedMenuItem === 'reward'}
                  onClick={() => {

                    setSelectedMenuItem('reward');
                    navigate('/reward')

                  }} // Use navigate function to handle click event
                >
                  <ListItemIcon>
                    <LoyaltyIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Reward" />
                </ListItemButton>
              </List>
              
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            <Toolbar />
            <AppRoutes />
          </Box>
        </>
      );
    } else {
      return null; // Render nothing if not authenticated
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {renderComponent()}
    </Box>
  );
}

export default AdminDashboard;
