import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Container } from '@mui/material';
import Logo from "../images/favicon.png";
import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthApi } from '../apis'; 
import { Navigate } from 'react-router-dom';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required'),
});

const LoginPage = () => {
  const [loading, setLoading] = useState(false);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      AuthApi.login(values)
        .then((res) => {
          // console.log("loginnnnnn",res.data)
          if (res?.data?.status === 200) {
            console.log("loginnnnnn",res.data)
            localStorage.setItem("user", 
            JSON.stringify(res.data.data));
            localStorage.setItem("token", res.data.data.token);

            window.location.href = "/services";
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        })
    },
  });
  
  return (
    <Container component="main" maxWidth="xl" sx={{
      backgroundColor: 'secondary.main',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      

    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 2,
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          width: '30%',
          height:"350px",
          maxWidth: '600px',
          marginTop:"50px",
        
          '@media (max-width: 1000px)': {
            width: '40%', // Adjust width for smaller screens
          },
          '@media (max-width: 600px)': {
            width: '50%', // Adjust width for smaller screens
          },
        }}
      >

        <img src={Logo} alt="Logo" style={{ width: '50px', marginLeft: '20px' }} />
        <Typography component="h1" variant="h5" color="primary">
          Sign in
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
