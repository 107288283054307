import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { RewardApi } from "../../../apis";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

function Reward() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);

  const fetchData = () => {
    RewardApi.getRewards().then((res) => {
      setData(res.data.data);
    });
  }

  useEffect(() => {
    fetchData()
  }, []);

  const handleRowClick = (id) => {
    navigate(`/services/detail/${id}`);
  };
  const handleDelete = (id) => {
    // Call the deleteService function passing the id
    RewardApi.deleteReward(id)
      .then(() => {
        fetchData()
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              Rewards
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate("/add-reward")}
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              background: "secondary.main",
              borderRadius: "5px",
              border: "none",
              boxShadow: "none",
              color: "#FFFFFF",
              outline: "none",
              "&:hover": {
                background: "secondary.main",
                outline: "none",
              },
            }}
          >
            Add Reward 
          </Button>
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ paddingRight: "20px" }}>Reward ID</th>
                  <th style={{ paddingLeft: "40px" }}>Description</th>
                  <th style={{ paddingLeft: "40px" }}>Coins</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {

                  data.length === 0 && <tr><td colSpan="4" style={{ textAlign: "center" }}>No data found</td></tr>
                }
                {

                  data.length > 0 && data.map((val, index) => (
                    <tr key={index} onClick={() => handleRowClick(val.id)}>
                      <td style={{ paddingLeft: "40px" }}>{val.id}</td>
                      <td style={{ paddingLeft: "40px" }}>
                        {val.description}
                      </td>
                      <td style={{ paddingLeft: "40px" }}>{val.coins}</td>
                      <td>
                        <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: "auto" }}>
                          <Button style={{ color: 'grey' }} onClick={(event) => { event.stopPropagation(); navigate(`/edit-reward/${val.id}`); }}>
                            <BorderColorIcon />
                          </Button>
                          <Button onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(val.id);
                          }}>
                            <DeleteIcon color="error" />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>

            </table>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Reward;
