import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import LoginPage from './pages/Login';
import Main from './pages/Admin/main';
import { BrowserRouter as Router, Routes, Route ,Navigate} from 'react-router-dom';
import "./App.css";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


function isAuthenticated() {
  return localStorage.getItem('token') !== null;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
        <ToastContainer transition={Slide} />
      <Router>
        <Routes>
        {/* <Route path="*" element={<Navigate to="/"   />} /> */}

          {!isAuthenticated() && (
            <Route path="/" element={<LoginPage />} />
          )}
          {isAuthenticated() && (
            <Route path="*" element={<Main />} />
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
