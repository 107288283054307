import axios from "../axiosConfig";

class Routes {

    // get all rewards
    getRewards = () => {
        let data = axios.get("/api/reward/get");
        return data;
    };

    // CRUD
    // create reward
    createReward = (val) => {
        let data = axios.post("/api/reward/create", val);
        return data;
    };

    // get reward by id
    getRewardById = (id) => {
        let data = axios.get(`/api/reward/edit/${id}`);
        return data;
    };

    // update reward
    updateReward = (id, val) => {
        let data = axios.put(`/api/reward/update/${id}`, val);
        return data;
    };

    // delete reward

    deleteReward = (id) => {
        let data = axios.delete(`/api/reward/delete/${id}`);
        return data;
    };
  
}



export default new Routes();