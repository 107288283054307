import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useFormik } from "formik";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { ServicesApi, } from "../../../apis";
import { makeStyles } from "@mui/styles";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
  },
});

const AddServiceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // useSearchParams
  const [searchParams] = useSearchParams();

  const [images, setImages] = useState([]); // State for multiple images
  const [imagePreviews, setImagePreviews] = useState([]); // State for image previews
  const [addOns, setAddOns] = useState([{ key: "", price: "" }]);
  const [editorState, setEditorState] = useState('');

  const validationSchema = yup.object({
    serviceId: yup.string().required("Service ID is required"),
    price: yup.number().positive(),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),

  });
  useEffect(() => {
    if (searchParams.get("serviceId") !== null) {
      // Fetch existing service details
      ServicesApi.getServiceDetailById(id)
        .then((res) => {
          console.log(res.data);
          formik.setFieldValue("price", res.data.data.price);
          formik.setFieldValue("title", res.data.data.title);
          formik.setFieldValue("description", res.data.data.description);

        });
    }
  }, [id]); // Fetch details when id changes


  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      serviceId: id,
      price: "",
      title: "",
      description: "",
      // addOns: addOns,
      // images: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form Values:", values);
    
      let serviceID = searchParams.get("serviceId");

      if (searchParams.get("serviceId") !== null) {
        // Update existing service details
        ServicesApi.updateServiceDetails(id, values).then(() => {
          navigate(
            `/services/detail/${serviceID}`,
            { replace: true }
          );
        });
      } else {
        ServicesApi.addServicesDetail(values).then(() => {
          navigate(
            `/services/detail/${id}`,
            { replace: true }
          );
        });
      }
    },
  });

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
    // Create image previews for each file
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
    formik.setFieldValue("images", files);
  };

  const handleImageDelete = (index) => {
    // Remove the selected image and its preview from the state
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
    // Update formik field value
    formik.setFieldValue("images", updatedImages);
  };

  const handleAddOnChange = (index, field, value) => {
    const updatedAddOns = [...addOns];
    updatedAddOns[index][field] = value;
    setAddOns(updatedAddOns);
    formik.setFieldValue("addOns", updatedAddOns);
  };

  const handleAddOnAdd = () => {
    setAddOns([...addOns, { key: "", price: "" }]);
  };

  const handleAddOnRemove = (index) => {
    const updatedAddOns = addOns.filter((_, i) => i !== index);
    setAddOns(updatedAddOns);
    formik.setFieldValue("addOns", updatedAddOns);
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => {
              let backId = searchParams.get("serviceId") !== null ? searchParams.get("serviceId") : id;
              navigate(
                `/services/detail/${backId}`, { replace: true }
              )
            }} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              {
                searchParams.get("serviceId") !== null ? "Edit" : "Add"
              } Service Details
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                "&:hover": {
                  background: "secondary.main",
                  outline: "none",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Price:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="price"
                  name="price"
                  type="number"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.price && Boolean(formik.errors.price)
                  }
                  helperText={formik.touched.price && formik.errors.price}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Title:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Description:
              </InputLabel>
              <CKEditor

                editor={ClassicEditor}
                data={
                  formik.values.description
                }
                onReady={editor => {
                  // You can also set the editor UI height dynamically here if needed
                  editor.ui.view.editable.element.style.height = '400px';
                }}
                config={{

                  ckfinder: {
                    uploadUrl: 'https://api.zahrasalonandspa.ca/api/service/upload-image',
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorState(data);
                  formik.setFieldValue("description", data);
                }}
              />
            </Grid>


          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddServiceDetails;
