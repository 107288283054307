// src/App.js
import React from 'react';
import Services from '../../pages/Admin/Services/viewService';
import AddServices from '../../pages/Admin/Services/addService';
import ServiceDetail from '../../pages/Admin/ServiceDetail/viewServiceDetail';
import AddServicesDetails from '../../pages/Admin/ServiceDetail/addServiceDetail';
import NotificationList from '../../pages/Admin/Notification/List';
import Reward from '../../pages/Admin/Reward/List';
import AddReward from '../../pages/Admin/Reward/Form';
import {  Route, Routes,Navigate } from 'react-router-dom'


function AppRoutes() {
  return (
        <Routes>
        
          <Route path="/" element={<Services />} />
          <Route path="/services" element={<Services />} />
          <Route path="/add-services" element={<AddServices />} />
          <Route path="/edit-services/:id" element={<AddServices />} />
          <Route path="/services/detail/:id" element={<ServiceDetail/>} />
          <Route path="/add-service-detail/:id" element={<AddServicesDetails/>} />
          <Route path="/notification" element={<NotificationList />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/add-reward" element={<AddReward />} />
          <Route path="/edit-reward/:id" element={<AddReward />} />

        </Routes>
  );
}

export default AppRoutes;
